import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Segment,
  Card,
  Button,
  Header,
  Icon,
  List,
  Divider
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'
import firebase from 'firebase/app'
import 'firebase/firestore'
import fecha from 'fecha'

import { Link } from '../../locales'
import StarCounter from '../Common/StarCounter'
import WishlistButton from '../Wishlist/WishlistButton'
import Modal from '../Modals/Modal'
import { modalActions } from '../Modals/state'
import { getFluidImage } from '../../utils/images'
import { dateFormat } from '../../utils/constants'

async function fetchHotelUrl({ hotelID, lang }) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection('hotels')
    .where('lang', '==', lang)
    .where('baseId', '==', hotelID)
    .get()

  if (querySnapshot.empty) {
    return null
  }

  const hotel = querySnapshot.docs[0].data()

  return `/destinations/${hotel.countrySlug}/hotels/${hotel.slug}`
}

class TourPackage extends Component {
  state = { hotelImage: '', hotelLead: '', url: '' }

  handleHotelModal = ({ hotelID, lead, images }) => {
    const { version, toggleModal, i18n } = this.props
    const modalId = version.name + version.versCode

    fetchHotelUrl({ hotelID, lang: i18n.language }).then(url =>
      this.setState({ url })
    )

    this.setState({ lead, images }, () => toggleModal(modalId))
  }

  render() {
    const { version, item, t, i18n, toggleModal } = this.props
    const { url, lead, images } = this.state

    const modalId = version.name + version.versCode

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{version.name}</Card.Header>

          <Card.Description>
            {t('tour-package.price')} {version.prices} CHF
          </Card.Description>

          {version.hotels && !!version.hotels.length && (
            <div>
              <Divider />
              <Card.Description>
                <Header as='h5' color='red'>
                  {t('tour-package.rooms')}:
                </Header>
                <List>
                  {version.hotels.map(
                    (
                      {
                        from,
                        to,
                        hotelName,
                        hotelStars,
                        roomName,
                        lead,
                        images,
                        hotelID
                      },
                      i
                    ) => (
                      <List.Item key={i}>
                        <List.Header>{`${t('tour-details.day')} ${from} - ${to}: ${hotelName}`}</List.Header>
                        {t('tour-package.categories')} {roomName}{' '}
                        <StarCounter count={hotelStars} />
                        {lead && (
                          <Icon
                            className='ta-info'
                            color='red'
                            link
                            onClick={() =>
                              this.handleHotelModal({ hotelID, lead, images })
                            }
                            style={{ display: 'inline-block' }}
                          />
                        )}
                      </List.Item>
                    )
                  )}
                </List>
              </Card.Description>
            </div>
          )}

          {!!version.package && (
            <div>
              <Divider />
              <Card.Description>
                <Header as='h5' color='red'>
                  {t('tour-package.included')}:
                </Header>
                <List>
                  <div dangerouslySetInnerHTML={{ __html: version.package }} />
                </List>
              </Card.Description>
            </div>
          )}

          {version &&
            version.schedule &&
            version.schedule.dates &&
            version.schedule.dates.length > 0 && (
              <div>
                <Divider />
                <Card.Description>
                  <Header as='h5' color='red'>
                    {t('tour-package.dates')}:
                  </Header>
                  <ul>
                    {version.schedule.dates.map(({ date, text }, i) => {
                      const formattedDate = fecha.format(
                        new Date(date),
                        dateFormat
                      )
                      return (
                        <li key={i}>
                          <strong>{formattedDate}</strong>
                          {text && `: ${text}`}
                        </li>
                      )
                    })}
                  </ul>
                </Card.Description>
              </div>
            )}
        </Card.Content>

        <WishlistButton item={{ ...item, version: version.name }} />

        {lead && (
          <Modal
            id={modalId}
            onClose={() => this.setState({ lead: '', images: '', url: '' })}
            closeIcon
          >
            {images && !!images.length && (
              <Img
                fluid={getFluidImage({ cloudinary: images[0].cloudinary })}
              />
            )}

            <Segment basic vertical>
              <div dangerouslySetInnerHTML={{ __html: lead }} />
            </Segment>

            {url && (
              <Link
                to={url}
                lang={i18n.language}
                aria-label='hotel page'
                onClick={() => toggleModal(modalId)}
              >
                <Button
                  size='large'
                  color='red'
                  icon
                  labelPosition='right'
                  inverted
                >
                  {t('item-list.more')}
                  <Icon className='ta-arrow-1-right' />
                </Button>
              </Link>
            )}
          </Modal>
        )}
      </Card>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/

const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(connect(null, mapDispatchToProps)(TourPackage))
