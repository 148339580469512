import React, { Component } from 'react'
import Lightbox from 'react-images'
import Img from 'gatsby-image'
import { Segment } from 'semantic-ui-react'

import { getFluidImage } from '../../utils/images'

class MapImage extends Component {
  state = { open: false }

  toggleOpen = () => this.setState(prev => ({ open: !prev.open }))

  render () {
    const { open } = this.state
    const { cloudinary, alt } = this.props

    const mapImage = getFluidImage({ cloudinary })
    const imagesSrc = [
      { src: mapImage.src, srcSet: mapImage.srcSet.split(',') }
    ]

    return (
      <Segment vertical basic onClick={this.toggleOpen}>
        <Img
          fluid={getFluidImage({
            cloudinary,
            maxWidth: 600
          })}
          style={{ cursor: 'pointer' }}
          alt={alt}
        />
        <Lightbox
          backdropClosesModal
          images={imagesSrc}
          isOpen={open}
          onClose={this.toggleOpen}
        />
      </Segment>
    )
  }
}

export default MapImage
